var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"service-page-card"},[(_vm.service)?[_c('div',{staticClass:"service-page__header"},[_c('div',{staticClass:"service-page__title"},[_vm._v(" "+_vm._s(_vm.service.name)+" ")]),(_vm.service.domain)?_c('div',{staticClass:"service-page__domain"},[_c('a',{attrs:{"href":_vm.service.domain}},[_vm._v(_vm._s(_vm.service.domain))])]):_vm._e()]),_c('div',{staticClass:"service-page__info"},[_c('div',{staticClass:"service-page__info-title"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("status")))+": "),_c('a-tag',{attrs:{"color":_vm.getTagColor}},[_vm._v(" "+_vm._s(_vm.$t(_vm.service.status))+" ")])],1)]),(_vm.service.ORDER_INFO)?_c('div',{staticClass:"service-page__info"},[_c('div',{staticClass:"service-page__info-title"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("invoice status")))+": "),_c('a-tag',{attrs:{"color":_vm.getInvoiceStatusColor}},[_vm._v(" "+_vm._s(_vm.$t("invoice_" + _vm.service.ORDER_INFO.invoicestatus))+" ")]),_c('router-link',{attrs:{"to":{
                name: 'invoiceFS',
                params: { uuid: _vm.service.ORDER_INFO.invoiceid },
              }}},[_c('a-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("open"))+" ")])],1)],1)]):_vm._e(),(_vm.service.groupname === 'SSL')?_c('div',{staticClass:"service-page__info"},[(true || _vm.service.SSL.sslstatus === 'Completed')?_c('div',{staticClass:"service-page__info-title"},[_vm._v(" "+_vm._s(_vm.$t("ssl_product.configuration status"))+": "),_c('a-tag',{attrs:{"color":_vm.getTagColorSSL}},[_vm._v(" "+_vm._s(_vm.$t("ssl_product.completed"))+" ")]),_c('router-link',{attrs:{"to":{
                name: 'ssl',
                params: {
                  id: _vm.$route.params.id,
                },
              }}},[_c('a-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("open"))+" ")])],1)],1):_c('div',{staticClass:"service-page__info-title"},[_vm._v(" "+_vm._s(_vm.$t("ssl_product.configuration status"))+": "),_c('a-tag',{attrs:{"color":_vm.getTagColorSSL}},[_vm._v(" "+_vm._s(_vm.$t("ssl_product.awaiting configuration"))+" ")]),_c('router-link',{attrs:{"to":{
                name: 'certificate',
                params: { id: _vm.$route.params.id },
              }}},[_c('a-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("ssl_product.configure"))+" ")])],1)],1)]):_vm._e(),_c('a-row',{attrs:{"gutter":[10, 10]}},_vm._l((_vm.info),function(elem){return _c('a-col',{key:elem.key,attrs:{"md":12,"xs":12,"sm":12}},[_c('div',{staticClass:"service-page__info"},[_c('div',{staticClass:"service-page__info-title"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("userService." + elem.title)))+": ")]),(elem.type == 'money')?_c('div',{staticClass:"service-page__info-value"},[_vm._v(" "+_vm._s(_vm.service[elem.key])+" "+_vm._s(_vm.user.currency_code || 'USD')+" ")]):(
                  elem.type == 'date' && _vm.service[elem.key] == '0000-00-00'
                )?_c('div',{staticClass:"service__info-value"},[_vm._v(" - ")]):(elem.type == 'date')?_c('div',{staticClass:"service-page__info-value"},[_vm._v(" "+_vm._s(_vm.service[elem.key] && new Intl.DateTimeFormat().format(new Date(_vm.service[elem.key])))+" ")]):(elem.type == 'text')?_c('div',{staticClass:"service-page__info-value"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.service[elem.key] && _vm.$t(_vm.service[elem.key].toLowerCase())))+" ")]):_c('div',{staticClass:"service-page__info-value"},[_vm._v(" "+_vm._s(_vm.service[elem.key])+" ")])])])}),1),_c(_vm.getModuleButtons,{tag:"component",attrs:{"service":_vm.service}})]:_c('loading')],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }